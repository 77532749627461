import React from "react"
import { PRODUCTS } from "../../../env"
import Button from "components/ui/button"

import Layout from "components/layout"
import SEO from "components/seo"
import Product from "components/products/product"

const IndexPage = () => {
  return (
    <Layout path="/products/">
      <SEO
        title="Products"
        description="Products Index Page Description"
        keywords={[
          `Rishaba`,
          `Butterfly`,
          `Home Appliances`,
          `Industrial Design`,
          `Engineering`,
          `Mold Design`,
          `Manufacturing`,
        ]}
        ogUrl="/products"
      />
      <h1 className="center hide-lg my-50">Products</h1>
      {Object.keys(PRODUCTS).map((product, index) => {
        return (
          <Product index={index} product={product} key={index}/>
        )
      })}
      <Button
        className="hide-sm mb-100-lg"
        name="CONTACT US"
        link="/contact"
        orientation="center"
      />
    </Layout>
  )
}

export default IndexPage
