import React from "react"
import { PRODUCTS } from "../../../env"
import ChevronRight from '../icons/chevronRight';

const Product = ({ product, index }) => {
  return (
    <div
      className={`products-page-gradient-lg ${
        index % 2 === 0
          ? "products-page-gradient-grey"
          : "products-page-gradient-white"
      } position-relative`}
    >
      <div
        className="container d-flex column-sm align-items-center justify-content-end py-30 mb-100-lg"
      >
        <div className="w-60-lg w-100-sm d-flex column justify-content-center align-items-center-sm ml-100-lg">
          <h2 className="font-size-66 font-size-25-sm m-0 font-bold text-align-center-sm">
            {PRODUCTS[product].name}
          </h2>
          <p className="w-60-lg w-80-sm text-secondary text-align-left-lg text-align-center-sm mt-20">
            {PRODUCTS[product].display_description}
          </p>
          <div className="d-flex mt-20">
            <a
              href={`/products/${product}`}
              className="mt-150-lg text-info mr-20 letter-spacing"
            >
              Learn more
              <ChevronRight className="ml-10" fill="#74BFFF" />
            </a>
            <a
              href="/contact"
              className="mt-150-lg text-info hide-lg letter-spacing"
            >
              Contact
            </a>
          </div>
        </div>
        <div className="w-50-lg w-100-sm animateFadeInRight position-relative">
          <img
            className="w-100"
            src={`${PRODUCTS[product].display_image}`}
            alt={`product ${index}`}
          />
          {PRODUCTS[product].coming_soon && (
            <div className="position-absolute w-50 bg-yellow border-radius-50 d-flex align-items-center justify-content-center coming-soon-badge-lg coming-soon-badge-sm">
              <p className="text-align-center">Coming Soon</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Product
