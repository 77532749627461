import React from "react"

const Button = ({ className, name, link, type, orientation }) => {
  return (
    <div className={`${orientation}`}>
      <button
        className={`btn ${className} font-size-20 cursor-pointer`}
        type={type}
        onClick={() => link && window && window.location.replace(link)}
      >
        {name}
      </button>
    </div>
  )
}

export default Button
